import React from 'react'
import { Helmet } from 'react-helmet'
import useSiteMetadata from '../../hooks/use-site-metadata'

const Seo = ({ title, description, lang, path }) => {
  const siteMetadata = useSiteMetadata()
  const url = [siteMetadata.siteUrl, path].join('')
  const fullTitle = [title, siteMetadata.title].filter(Boolean).join(' | ')

  return (
    <>
      <Helmet>
        {/* General tags */}
        <title>{fullTitle}</title>
        <html lang={lang || 'ar'}></html>

        <meta name="description" content={description} />

        {/* OpenGraph tags */}
        <meta property="og:type" content="article" />
        <meta property="og:title" content={fullTitle} />
        <meta property="og:description" content={description} />
        <meta
          property="og:image"
          content={`https://mugshotbot.com/m?theme=two_up&mode=light&color=indigo&pattern=lines_in_motion&image=7a772170&hide_watermark=true&url=${url}`}
        />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="watheq_show" />
        <meta name="twitter:title" content={fullTitle} />
        <meta name="twitter:description" content={description} />
        <meta
          name="google-site-verification"
          content="Vyfl-qUqW6XwhB5QkMurOBQeTI--dNcoN-EJZl5NckY"
        />

        {/** Google tag (gtag.js) analytics   */}
        {/**
    <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-C9W7GBZRXK"
        ></script>
*/}

        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-C9W7GBZRXK"
        ></script>
        <script src="google-tag.js"></script>
      </Helmet>
    </>
  )
}

export default Seo
